import React from "react"
import Seo from "../components/seo"
import Footer from "../components/footer"
import MenuEn from "../components/menu_en"
import "../css/style.css"

const EnPage = () => (
  <>
    <Seo title="Menu" />
    <MenuEn />
    <div className="footer-1">
      <Footer />
    </div>
  </>
)

export default EnPage
